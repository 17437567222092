<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="outputMessages"
      >
        <template #created="{ item: { created: _dateForFormatting } }">
          {{ formatDisplayDate(_dateForFormatting) }}
        </template>

        <template #actions="{ item }">
          <v-layout justify-space-between>
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-btn
                    color="info"
                    small
                    icon
                    @click="showMessage(item.id)"
                  >
                    <v-icon>
                      remove_red_eye
                    </v-icon>
                  </v-btn>
                </span>
              </template>
              Show message
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-btn
                    :disabled="!item.sourceUrl"
                    :href="item.sourceUrl"
                    target="_blank"
                    color="info"
                    small
                    icon
                  >
                    <v-icon>
                      backup
                    </v-icon>
                  </v-btn>
                </span>
              </template>
              Show source
            </v-tooltip>
          </v-layout>
        </template>

        <template #state="{ item: { state } }">
          <dsb-entity-badge :entity="state" />
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>

    <vbt-dialog
      v-model="messageBodyDialog"
      title="Message body"
    >
      <vbt-code
        :value="messageBody"
        readonly
      />
    </vbt-dialog>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace, EnumsNamespace } from '@store/types';

import { namespace as OutputMessagesNamespace, ActionTypes as OutputMessagesActionTypes } from '../store.types';

const dataLoadingOptions = {
  getterName: OutputMessagesActionTypes.GET_OUTPUT_MESSAGES,
  contentName: 'outputMessages',
};
const paginationOptions = { contentName: 'outputMessages' };
const searchOptions = {
  initialSearchDataFields: [
    'basketId', 'pressSheetId', 'type', 'state',
  ],
};

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Job Number', value: 'basketId' },
  { text: 'Press Sheet ID', value: 'pressSheetId' },
  { text: 'Type', value: 'messageType' },
  { text: 'Created Date', value: 'created' },
  { text: 'Details', value: 'details' },
  { text: 'State', value: 'state' },
  { text: '', value: 'actions', width: '95px' },
]);

export default {
  name: 'DsbInputMessages',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      messageBodyDialog: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
    ...mapState(OutputMessagesNamespace, ['outputMessages']),
    ...mapState(OutputMessagesNamespace, {
      messageBody: ({ outputMessage: { body } }) => {
        try {
          return JSON.stringify(JSON.parse(body), null, '\t');
        } catch (e) {
          return '';
        }
      },
    }),

    ...mapGetters(EnumsNamespace, ['outputMessagesTypes', 'outputMessagesStates']),

    searchFieldsConfig() {
      return [
        {
          value: 'basketId',
          label: 'Job Number',
          type: 'text',
          flex: 'sm3',
        },
        {
          value: 'pressSheetId',
          label: 'Press Sheet ID',
          type: 'text',
          flex: 'sm3',
        },
        {
          value: 'type',
          label: 'Type',
          items: this.outputMessagesTypes,
          type: 'select',
          flex: 'sm3',
        },
        {
          value: 'state',
          label: 'State',
          items: this.outputMessagesStates,
          type: 'select',
          flex: 'sm3',
        },
      ];
    },

    headers() {
      return this.outputMessages.some(({ details }) => details)
        ? headers.map((h) => (h.value === 'details' ? { ...h, width: '400px' } : h))
        : headers;
    },
  },

  methods: {
    ...mapActions(OutputMessagesNamespace, [OutputMessagesActionTypes.GET_OUTPUT_MESSAGES]),
    ...mapActions(OutputMessagesNamespace, {
      showMessage(dispatch, id) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, OutputMessagesActionTypes.GET_OUTPUT_MESSAGE, { id }),
          fullscreenLoadingSpinner: true,
          onSuccess: () => {
            if (this.messageBody) {
              this.messageBodyDialog = true;
            } else {
              this.$VBlackerTheme.warning('Message body is empty');
            }
          },
        });
      },
    }),

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
