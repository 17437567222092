var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.outputMessages},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var _dateForFormatting = ref.item.created;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"color":"info","small":"","icon":""},on:{"click":function($event){return _vm.showMessage(item.id)}}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)],1)]}}],null,true)},[_vm._v(" Show message ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":!item.sourceUrl,"href":item.sourceUrl,"target":"_blank","color":"info","small":"","icon":""}},[_c('v-icon',[_vm._v(" backup ")])],1)],1)]}}],null,true)},[_vm._v(" Show source ")])],1)]}},{key:"state",fn:function(ref){
var state = ref.item.state;
return [_c('dsb-entity-badge',{attrs:{"entity":state}})]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1),_c('vbt-dialog',{attrs:{"title":"Message body"},model:{value:(_vm.messageBodyDialog),callback:function ($$v) {_vm.messageBodyDialog=$$v},expression:"messageBodyDialog"}},[_c('vbt-code',{attrs:{"value":_vm.messageBody,"readonly":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }